import React from 'react';
import './AdvertismentLR_Ribbon.css';

const Ribbons = () => {
    return (
        <>
            <div className="left-ribbon">
                <img src='https://www.propertyplateau.com/wp-content/uploads/2024/08/Screenshot-2024-08-01-at-9.13.53 AM.png'></img>
            </div>
            <div className="right-ribbon">
            <img src='https://www.propertyplateau.com/wp-content/uploads/2024/08/Screenshot-2024-08-01-at-9.13.53 AM.png'></img>
            </div>
        </>
    );
};

export default Ribbons;
