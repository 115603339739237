import React, { useState, useEffect } from 'react';
import { Box, LinearProgress, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';


const images = [
  'https://s3.ap-south-1.amazonaws.com/lodhagroup.in-tfz/1263x550_Gymnasium-marq.jpg',
  'https://s3.ap-south-1.amazonaws.com/lodhagroup.in-tfz/1263x550_Alfresco-Pavilion-marq.jpg',
  'https://s3.ap-south-1.amazonaws.com/lodhagroup.in-tfz/1263x550_Gymnasium-marq.jpg',
  'https://s3.ap-south-1.amazonaws.com/lodhagroup.in-tfz/1263x550_Gymnasium-marq.jpg',
  'https://s3.ap-south-1.amazonaws.com/lodhagroup.in-tfz/1263x550_Alfresco-Pavilion-marq.jpg',
  'https://s3.ap-south-1.amazonaws.com/lodhagroup.in-tfz/1263x550_Gymnasium-marq.jpg',
  // Add paths to more images
];

const StoryProgress = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          setCurrentImage((prevImage) => (prevImage + 1) % images.length);
          return 0;
        }
        return prevProgress + 1;
      });
    }, 100); // Progress bar updates every 100ms for a smoother transition

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handlePrev = () => {
    setCurrentImage((prevImage) => (prevImage === 0 ? images.length - 1 : prevImage - 1));
    setProgress(0);
  };

  const handleNext = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    setProgress(0);
  };

  return (
    <Box
      sx={{
        width: '24%',
        position: 'relative',
        margin: '0 auto', // Center the component horizontally
        top: '-270px',
        left:"470px", 
        right:"150px",
        transform: 'translateY(-50%)', // Center the component vertically
      }}
    >
      <IconButton
        onClick={handlePrev}
        sx={{
          position: 'absolute',
          left: '0',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
          backgroundColor: 'white',
          borderRadius: '50%',
          padding: '10px',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
        }}
      >
        <ArrowBackIos />
      </IconButton>
      <img
        src={images[currentImage]}
        alt={`Story ${currentImage}`}
        style={{ width: '100%', height: '350px' }}
      />
      <IconButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          right: '0',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
          backgroundColor: 'white',
          borderRadius: '50%',
          padding: '10px',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
        }}
      >
        <ArrowForwardIos />
      </IconButton>
      <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, display: 'flex' }}>
        {images.map((_, index) => (
          <LinearProgress
            key={index}
            variant="determinate"
            value={currentImage > index ? 100 : currentImage === index ? progress : 0}
            sx={{
              flex: 1,
              mx: 0.25, // Adjust the spacing between progress bars
              '& .MuiLinearProgress-bar': {
                transition: 'width 0.1s linear',
              },
              height: '5px', // Reduce the height of the progress bars
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default StoryProgress;
