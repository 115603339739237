import React from 'react';
import './Inshorts.css';

const ImageGrid = () => {
    const items = [
        {
            url: 'https://th-i.thgim.com/public/incoming/gstys8/article68469188.ece/alternates/PORTRAIT_230/AFP_367777X.jpg',
            caption: 'Watch: What is the international community saying on Bangladesh violence?',
            link: 'https://example.com/page1'
        },
        {
            url: 'https://th-i.thgim.com/public/incoming/i3v495/article68468143.ece/alternates/PORTRAIT_230/IMG_RESTAURANT_2_1_K0D4NCNM.jpg',
            caption: 'Watch: Wayanad landslides: Free food provided by Chef Suresh Pillai-run restaurant',
            link: 'https://example.com/page2'
        },
        {
            url: 'https://th-i.thgim.com/public/videos/8qb7hw/article68464574.ece/alternates/PORTRAIT_230/Realme%20phone%2013%20Pro.jpg',
            caption: 'Realme 13 Pro| Price and features',
            link: 'https://example.com/page3'
        },
        {
            url: 'https://th-i.thgim.com/public/incoming/avclqf/article68460855.ece/alternates/PORTRAIT_230/bangla1.jpeg',
            caption: 'Watch: Why should India worry about internal matters in Bangladesh?',
            link: 'https://example.com/page4'
        },
        {
            url: 'https://th-i.thgim.com/public/incoming/dawyx5/article68452900.ece/alternates/PORTRAIT_230/2162986023.jpg',
            caption: 'Watch: Eiffel Tower dazzles at opening ceremony of Paris Olympics',
            link: 'https://example.com/page5'
        }
    ];

    return (
        <div>
            <h2 className="section-title">Prop Shorts</h2>
            <div className="container">
                {items.map((item, index) => (
                    <div className="card" key={index}>
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                            <div className="image-container">
                                <img src={item.url} alt={item.caption} className="main-image" />
                                <img src="https://via.placeholder.com/50/FFFFFF" alt="logo" className="logo-image" />
                                <div className="progress-bar"></div>
                            </div>
                            <p>{item.caption}</p>
                        </a>
                    </div>
                ))}
            </div>
            <div className="see-more">
                <a href="https://example.com/seemore" target="_blank" rel="noopener noreferrer">SEE MORE</a>
            </div>
        </div>
    );
};

export default ImageGrid;
