import React from 'react';
import './TrendingProjects.css';

const videos = [
  {
    title: " Mumbai's Luxury Housing Market Hits New Heights: Sales of luxury homes priced over ₹10 crore increased by 8% in the first half of 2024, reaching a record ₹12,300 crore.",
    image: "https://bsmedia.business-standard.com/_media/bs/img/article/2021-08/22/full/1629638057-0693.jpg?im=FeatureCrop,size=(826,465)",
  },
  {
    title: "Unprecedented Demand for High-End Properties: A report highlights a surge in luxury unit sales, with 1,040 units sold in the past year, reflecting strong market confidence.",
    image: "https://www.propertyplateau.com/wp-content/uploads/2024/07/1722229202_a5bb0143-9356-439a-b1eb-3525aa19badd-3x2-1.jpeg",
  },
  {
    title: "Puravankara's Impressive Revenue Growth: The developer reported a 112% increase in revenue for Q4 FY24, launching multiple projects in high-demand areas.",
    image: "https://bsmedia.business-standard.com/_media/bs/img/article/2024-05/24/full/20240524101148.jpg",
  },
  {
    title: "Emerging Luxury Hotspots in Mumbai: Goregaon East has emerged as a new luxury hotspot, with significant sales value growth in primary housing.",
    image: "https://www.propertyplateau.com/wp-content/uploads/2024/07/1722229202_a5bb0143-9356-439a-b1eb-3525aa19badd-3x2-1.jpeg",
  },
  
  
];

const TrendingVideos = () => {
  return (
    <div className="trending-videos">
      <h2>TRENDING News</h2>
      <div className="videos-container">
        {videos.map((video, index) => (
          <div className="video-card" key={index}>
            <img src={video.image} alt={video.title} />
            <div className="video-info">
              <p>{video.title}</p>
              <div className="play-button"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingVideos;
