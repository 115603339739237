import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Reel from './Reel';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`;

const NewsContainer = styled.div`
  width: 70%;
`;

const NewsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  &:last-child {
    border-bottom: none;
  }
`;

const NewsText = styled.div`
  flex: 1;
`;

const NewsTitle = styled.h2`
  font-size: 1.5em;
  margin: 0;
`;

const NewsContent = styled.p`
  font-size: 1em;
  margin: 5px 0;
`;

const NewsUpdated = styled.span`
  font-size: 0.9em;
  color: #888;
`;

const Image = styled.img`
  width: 150px;
  height: 100px;
  object-fit: cover;
  margin-left: 10px;
`;

const AdContainer = styled.div`
  width: 25%;
  height:55%;
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
`;

const fadeInOut = keyframes`
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`;

const AdvertisementImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  animation: ${fadeInOut} 9s infinite;
`;

const App = () => {
  const ads = [
    'https://www.propertyplateau.com/wp-content/uploads/2024/07/1522572088058487343-1.png',
    'https://www.propertyplateau.com/wp-content/uploads/2024/07/1522572088058487343-1.png',
    'https://www.propertyplateau.com/wp-content/uploads/2024/07/1522572088058487343-1.png'
  ];

  const [currentAd, setCurrentAd] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAd((prevAd) => (prevAd + 1) % ads.length);
    }, 9000); // Change ad every 9 seconds

    return () => clearInterval(interval);
  }, [ads.length]);

  return (
    <Container>
      <NewsContainer>
        <NewsItem>
          <NewsText>
            <NewsTitle>DLF's growth potential anchors market confidence, brick by brick</NewsTitle>
            <NewsContent>Constructing value: With fortified finances, real estate major builds on sector consolidation.</NewsContent>
            <NewsUpdated>Updated On : 28 Jul 2024 | 9:36 PM IST</NewsUpdated>
          </NewsText>
          <Image src="https://bsmedia.business-standard.com/_media/bs/img/article/2024-07/28/full/1722182732-9488.jpg?im=FeatureCrop,size=(328,185)" alt="news" />
        </NewsItem>
        <NewsItem>
          <NewsText>
            <NewsTitle>DLF-GIC JV firm DCCDL's office rental income rises 11% to Rs 942 cr in Q1</NewsTitle>
            <NewsContent>Realty firm DLF's rental arm DCCDL has posted an 11 per cent annual increase in office rental income to Rs 942 crore during the first quarter of this fiscal on better demand for its premium workspace. DLF Cyber City…</NewsContent>
            <NewsUpdated>Updated On : 28 Jul 2024 | 4:33 PM IST</NewsUpdated>
          </NewsText>
          <Image src="https://bsmedia.business-standard.com/_media/bs/img/article/2024-07/28/full/1722182732-9488.jpg?im=FeatureCrop,size=(328,185)" alt="news" />
        </NewsItem>
        <NewsItem>
          <NewsText>
            <NewsTitle>DLF plans to launch Rs 1.04 trn worth real estate projects in medium term</NewsTitle>
            <NewsContent>Realty major DLF plans to launch nearly 37 million square feet area for sale in the medium term across various cities with revenue potential of Rs 1.04 lakh crore as part of its strategy to encash strong demand for luxury homes…</NewsContent>
            <NewsUpdated>Updated On : 26 Jul 2024 | 11:42 PM IST</NewsUpdated>
          </NewsText>
          <Image src="https://bsmedia.business-standard.com/_media/bs/img/article/2024-07/28/full/1722182732-9488.jpg?im=FeatureCrop,size=(328,185)" alt="news" />
        </NewsItem>
        <NewsItem>
          <NewsText>
            <NewsTitle>DLF plans to launch Rs 1.04 trn worth real estate projects in medium term</NewsTitle>
            <NewsContent>Realty major DLF plans to launch nearly 37 million square feet area for sale in the medium term across various cities with revenue potential of Rs 1.04 lakh crore as part of its strategy to encash strong demand for luxury homes…</NewsContent>
            <NewsUpdated>Updated On : 26 Jul 2024 | 11:42 PM IST</NewsUpdated>
          </NewsText>
          <Image src="https://bsmedia.business-standard.com/_media/bs/img/article/2024-07/28/full/1722182732-9488.jpg?im=FeatureCrop,size=(328,185)" alt="news" />
        </NewsItem>
        <NewsItem>
          <NewsText>
            <NewsTitle>DLF plans to launch Rs 1.04 trn worth real estate projects in medium term</NewsTitle>
            <NewsContent>Realty major DLF plans to launch nearly 37 million square feet area for sale in the medium term across various cities with revenue potential of Rs 1.04 lakh crore as part of its strategy to encash strong demand for luxury homes…</NewsContent>
            <NewsUpdated>Updated On : 26 Jul 2024 | 11:42 PM IST</NewsUpdated>
          </NewsText>
          <Image src="https://bsmedia.business-standard.com/_media/bs/img/article/2024-07/28/full/1722182732-9488.jpg?im=FeatureCrop,size=(328,185)" alt="news" />
        </NewsItem>
        <NewsItem>
          <NewsText>
            <NewsTitle>DLF plans to launch Rs 1.04 trn worth real estate projects in medium term</NewsTitle>
            <NewsContent>Realty major DLF plans to launch nearly 37 million square feet area for sale in the medium term across various cities with revenue potential of Rs 1.04 lakh crore as part of its strategy to encash strong demand for luxury homes…</NewsContent>
            <NewsUpdated>Updated On : 26 Jul 2024 | 11:42 PM IST</NewsUpdated>
          </NewsText>
          <Image src="https://bsmedia.business-standard.com/_media/bs/img/article/2024-07/28/full/1722182732-9488.jpg?im=FeatureCrop,size=(328,185)" alt="news" />
        </NewsItem>

        


        
      </NewsContainer>
      <AdContainer>
        <AdvertisementImage src={ads[currentAd]} alt="Advertisement" />
      </AdContainer>
    </Container>
  );
};

export default App;
