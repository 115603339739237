import React, { useRef, useState } from 'react';
import './VideoAdvertisment.css';

const VideoPlayer = () => {
  const [isMuted, setIsMuted] = useState(true);
  const iframeRef = useRef(null);

  const toggleMute = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const iframeWindow = iframe.contentWindow;
      iframeWindow.postMessage('{"event":"command","func":"mute","args":""}', '*');
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="ad-container">
      <div className="ad-box">
        <div className="ad-header">Advertisement</div>
        <div className="video-container">
          <iframe
            ref={iframeRef}
            className="video-element"
            src="https://www.youtube.com/embed/GfJwDl7P6Gk?autoplay=1&mute=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      
        
      </div>
    </div>
  );
};

export default VideoPlayer;
