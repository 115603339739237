import React, { useEffect, useState } from 'react';
import './Navbar.css';

const NavBar = () => {
  const [sticky, setSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <nav className={`navbar ${sticky ? 'sticky' : ''}`}>
        <div className="left-section">
          <div className="hamburger" onClick={toggleMenu}>
            {menuOpen ? (
              <span className="cross">✕</span>  
            ) : (
              <span className="hamburger-icon">☰</span> 
            )}
          </div>
        </div>

        <div className="logo">
          <img src="https://propertyplateaumedia.s3.ap-south-1.amazonaws.com/wp-content/uploads/2024/09/03030642/PPTimes_bold.png" alt="Logo" />
        </div>

        <div className="nav-items">
          <a href="/real-estate">Real Estate</a>
          <a href="/holiday-homes">Holiday Homes</a>
          <a href="/podcast-sessions">Podcast Sessions</a>
        </div>

        <div className="right-section">
          <button className="subscribe">Join Our Newsletter</button>
        </div>
      </nav>

      {/* Sidebar Menu Drawer */}
      <div className={`menu-drawer ${menuOpen ? 'open' : ''}`}>
        <div className="menu-header">
          <button className="close-btn" onClick={toggleMenu}>✕</button>
        </div>
        <ul className="menu-links">
          <li><a href="/">Home</a></li>
          <li><a href="/latest-news">Latest News</a></li>
          <li><a href="/olympics-2024">Olympics 2024</a></li>
          <li><a href="/cricket">Cricket</a></li>
          <li><a href="/real-estate">Real Estate</a></li>
          <li><a href="/entertainment">Entertainment</a></li>
          <li><a href="/trending">Trending</a></li>

          <li className="brand-section-title">Our Brands</li>
          <li><a href="https://www.propertyplateau.com/" target="_blank" rel="noopener noreferrer">Property Plateau</a></li>
          <li><a href="https://propplateau.com/" target="_blank" rel="noopener noreferrer">Prop Plateau</a></li>
          <li><a href="https://holidayhomes.propertyplateau.com/" target="_blank" rel="noopener noreferrer">Holiday Homes</a></li>
          <li><a href="https://latitudeandlongitudegroup.com/" target="_blank" rel="noopener noreferrer">Latitude and Longitude</a></li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
