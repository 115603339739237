import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './NewsArticle.css';

const apiUrl = process.env.REACT_APP_API_URL;

const NewsArticle = () => {
  const [newsTitle, setNewsTitle] = useState('');
  const [newsDescription, setNewsDescription] = useState('');
  const [newsImage, setNewsImage] = useState('');
  const [newsItems, setNewsItems] = useState([]);
  const [latestPost, setLatestPost] = useState(null); 
  const [latestNews, setLatestNews] = useState([
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
    "Nisi ut aliquip ex ea commodo consequat duis aute irure.",
    "Duis aute irure dolor in reprehenderit in voluptate velit esse.",
    "Cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.",
    "Sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "Curabitur pretium tincidunt lacus. Nulla gravida orci a odio.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${apiUrl}/public/posts`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data); 
        if (data && data.length > 0) {
          // Sort posts by ObjectId (_id) in descending order (most recent to oldest)
          const sortedData = data.sort((a, b) => {
            return parseInt(b._id.slice(0, 8), 16) - parseInt(a._id.slice(0, 8), 16);
          });
          
          // Set the latest post for the first column
          const latestPost = sortedData[0]; 
          setNewsTitle(latestPost.title); 

          const imageURL = latestPost.picture.startsWith('http')
            ? latestPost.picture
            : `${apiUrl}/${latestPost.picture}`;
          setNewsImage(imageURL); 

          setLatestPost({
            title: latestPost.title,
            category: latestPost.categories?.[0], // Use first category from array
            description: latestPost.description,
          });

          // Set the remaining posts for the sidebar
          const items = sortedData.slice(1, 5).map(post => ({
            id: post._id,
            title: post.title,
            category: post.categories?.[0], // Use first category from array
            image: post.picture.startsWith('http')
              ? post.picture
              : `${apiUrl}/${post.picture}`,
          }));

          setNewsItems(items); 
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const getFirst50Words = (text) => {
    return text.split(' ').slice(0, 50).join(' ') + '...';
  };

  const formatTitleForURL = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '');
  };

  const handleNavigation = (title, category) => {
    const formattedTitle = formatTitleForURL(title);
    const formattedCategory = formatTitleForURL(category); // Format category for URL usage
    navigate(`/newsshorts/${formattedCategory}/${formattedTitle}`);
  };

  return (
    <div className="container">
      {/* Latest Post in the First Column */}
      <div className="main-content">
        <div onClick={() => handleNavigation(latestPost?.title, latestPost?.category)} className="news-link" style={{ cursor: 'pointer' }}>
          <h1 className="title">{newsTitle}</h1>
          <img className="main-image" src={newsImage} alt="Article" />
        </div>
        <p className="description" dangerouslySetInnerHTML={{ __html: getFirst50Words(latestPost?.description || '') }}></p>
      </div>

      <div className="divider-vertical"></div>

      {/* Sidebar for 2nd to 5th Articles */}
      <div className="sidebar">
        {newsItems.map((item, index) => (
          <div key={index} onClick={() => handleNavigation(item.title, item.category)} className="news-item-link" style={{ cursor: 'pointer' }}>
            <div className="news-item">
              <span className="news-text">{item.title}</span>
              <img className="featured-image" src={item.image} alt={item.title} />
            </div>
          </div>
        ))}

        <div className="advertisement">
        </div>
      </div>

      <div className="divider-vertical"></div>

      {/* Latest News Section */}
      <div className="latest-news">
        <h2 className="latest-news-heading">Most Popular News</h2>
        {latestNews.slice(0, 7).map((text, index) => (
          <div className="latest-item" key={index}>
            {text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsArticle;
