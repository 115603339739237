// import './App.css';
// import NewsArticle from './components/News_Article';
// import Home from "./components/home"
// import Slider from "./components/AdSlider"
// import Navbar from './components/Navbar';
// import BannerCarousel from "./components/BannerCarousel"
// import VideoAdvertisment from "./components/VideoAdvertisment"
// import MarqueeTag from "./components/MarqueeTag"
// import TrendingProjects from "./components/TrendingProjects"
// import Alotnews from "./components/AlotNews"
// import Reel from "./components/Reel"
// import AdvertismentLR_Ribbon from "./components/AdvertismentLR_Ribbon"
// import Inshorts from "./components/Inshorts";
// import PostPage from './PostPage/postpage';
// function App() {
  
//   return (
//     <div className="App">
       
//       {/* <Home/> */}
//       {/* <BannerCarousel/> */}
//       <Navbar/> 
//       <MarqueeTag/>
//       <NewsArticle/>
//       <VideoAdvertisment/>
//       <TrendingProjects/>
//       <Alotnews/>
//       <Reel/>
//       <AdvertismentLR_Ribbon/>
//       <Inshorts/> 
     
     

//     </div>
//   );
// }


// export default App;



// new one on 27 aug
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import necessary components from react-router-dom
import NewsArticle from './components/News_Article';
import Home from "./components/home";
import Slider from "./components/AdSlider";
import Navbar from './components/Navbar';
import BannerCarousel from "./components/BannerCarousel";
import VideoAdvertisment from "./components/VideoAdvertisment";
import MarqueeTag from "./components/MarqueeTag";
import TrendingProjects from "./components/TrendingProjects";
import Alotnews from "./components/AlotNews";
import Reel from "./components/Reel";
import AdvertismentLR_Ribbon from "./components/AdvertismentLR_Ribbon";
import Inshorts from "./components/Inshorts";
import PostPage from './PostPage/postpage';
import NewsShorts from './components/NewsShorts'; // Import your NewsShorts component

function App() {
  return (
    <Router>
    <div className="App">
      <Navbar />
      <MarqueeTag />
      <Routes>
        {/* Home Route */}
        <Route path="/" element={
          <>
            <NewsArticle />
            <VideoAdvertisment />
            <TrendingProjects />
            <Alotnews />
            <Reel />
            <AdvertismentLR_Ribbon />
            <Inshorts />
          </>
        } />
        {/* Dynamic route for news article by title */}
        <Route path="/newsshorts/:title" element={<NewsShorts />} /> {/* This is the dynamic route for individual articles */}
      </Routes>
    </div>
  </Router>
  );
}

export default App;
